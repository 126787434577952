import { mapGetters, mapActions, mapState } from 'vuex'
import authMixin from '@/mixins/auth-mixin'
const AdminItem = () => import(/* webpackChunkName = "c-admin-item" */'@/components/AdminItem')
const AddAdminForm = () => import(/* webpackChunkName = "c-add-admin-form" */'@/components/AddAdminForm')

export default {
  name: 'Admin',
  mixins: [authMixin],
  components: {
    AdminItem,
    AddAdminForm
  },
  data () {
    return {
      visibleAddForm: false,
      editId: ''
    }
  },
  created () {
    this.getAdminsAction({
      success: () => {}
    })
  },
  computed: {
    ...mapGetters('user', ['token']),
    ...mapState('admin', ['admins']),
    selectedEditAdmin () {
      return this.admins.find(admin => admin.id === this.editId)
    }
  },
  methods: {
    ...mapActions('admin', ['getAdminsAction', 'deleteAdminAction']),
    handleSuccessDelete () {
      this.$swal({
        icon: 'success',
        title: 'Success Delete',
        showConfirmButton: false,
        timer: 1500
      })
      this.getAdminsAction({
        success: () => {}
      })
    },
    handleDeleteAdmin (id) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan dapat mengembalikan ini!',
        icon: 'warning',
        confirmButtonText: 'Yes, hapus!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAdminAction({
            success: this.handleSuccessDelete,
            data: {
              id: id,
              token: this.token
            }
          })
        }
      })
    },
    toggleAddForm (add = false) {
      if (add) this.editId = ''
      this.visibleAddForm = !this.visibleAddForm
    },
    handleEditAdmin (id) {
      this.editId = id
      this.toggleAddForm()
    }
  }
}
